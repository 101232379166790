import { UIElementProps } from "types/prop-types";

// import logo from "assets/images/logo-jornada.svg";
const logo = require("assets/images/logo-jornada.png");
const logoDark = require("assets/images/logo-jornada-dark.png");

export default function LogoJornada({
	dark,
	style,
	className,
}: UIElementProps) {
	return (
		<img
			src={!dark ? logo : logoDark}
			className={`pointer-none ${className}`}
			style={style}
		/>
	);
}

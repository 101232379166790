import { CSSProperties, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Eliza from "components/ui/Eliza";
import LogoJornada from "components/ui/LogoJornada";
import LogoOCB from "components/ui/LogoOCB";
import Shape from "components/ui/Shape";
import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";
import { motion } from "framer-motion";
import { UIElementProps } from "types/prop-types";

import "./styles.scss";
import AudioHome from "components/audio/AudioHome";
import VolumeControl from "components/ui/VolumeControl";
import { useDialog } from "context/DialogContext";
import { fetchSettings } from "services/jornada.service";
import usePointsStore from "store";

const step1Bg = require("assets/images/step1-bg.png");

function AnimatedText({ children, delay }: UIElementProps) {
	return (
		<motion.div
			initial={{
				translateX: -50,
				opacity: 0,
			}}
			animate={{
				translateX: 0,
				opacity: 1,
			}}
			transition={{ duration: 1, ease: "easeOut", delay }}
		>
			{children}
		</motion.div>
	);
}

const styles: any = {
	eliza: {
		position: "absolute",
		bottom: 10,
		right: 10,
		width: "300px",
		height: "300px",
		border: "1px solid #fff",
	},
	logoJornada: {
		position: "absolute",
		top: 10,
		left: 10,
		width: "300px",
		height: "300px",
	},
};

export function Home() {
	const location = useLocation();
	const navigate = useNavigate();
	const { reset } = useCoop();
	const { closeDialog } = useDialog();
	const { isAuthenticated, checkGameIsEnabled } = useAuth();
	const [showForm, setShowForm] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const { resetPointsStore } = usePointsStore();

	useEffect(() => {
		// console.log(location.state);
		const locationState: any = location.state;
		if (locationState && locationState.success) {
			setShowForm(true);
		}
	}, [location]);

	useEffect(() => {
		if (isAuthenticated) {
			navigate("/passo/1");
		}
	}, [isAuthenticated]);

	useEffect(() => {
		reset();
		closeDialog();
		resetPointsStore();
	}, [reset, resetPointsStore]);

	useEffect(() => {
		checkGameIsEnabled();
	}, []);

	if (!initialized) {
		return (
			<div
				id="home"
				className="cursor-pointer relative w-full h-full"
				onClick={() => setInitialized(true)}
			>
				<div className="absolute top-0 left-0 bottom-0 right-0 m-auto bg-blue-dark rounded-full w-96 h-96 animate-in zoom-in flex flex-row items-center justify-center p-16">
					<LogoJornada />
					<p className="text-white text-center absolute top-full text-3xl mt-10 font-bold">
						TOQUE PARA INICIAR
					</p>
				</div>
				<LogoOCB white className="logo-ocb" />
			</div>
		);
	}

	return (
		<>
			<AudioHome />

			<div className="absolute z-30 top-10 right-10">
				{/* <VolumeControl /> */}
			</div>

			<div
				id="home"
				className="cursor-pointer relative w-full h-full"
				onClick={() => navigate("/login")}
			>
				<div className="logo animate animate-wiggle bg-blue-dark rounded-full">
					<LogoJornada className="w-full h-full object-contain absolute p-16" />
				</div>

				<div className="absolute bottom-14 left-24 text-white font-bold font-cond text-5xl bg-blue-dark p-10 rounded-2xl">
					<AnimatedText delay={0}>Olá, bem-vindo(a)!</AnimatedText>
					<AnimatedText delay={0.3}>
						Eu sou a{" "}
						<span
							className="
					txt-eliza"
						>
							Polly
						</span>
						, e vou te acompanhar
					</AnimatedText>
					<AnimatedText delay={0.5}>na Jornada Game of Privacy.</AnimatedText>
					<AnimatedText delay={0.8}>
						<div className="mt-6">
							Nela, você vai descobrir o melhor caminho para
						</div>
					</AnimatedText>
					<AnimatedText delay={1}>
						garantir de forma rápida e descomplicada que,
					</AnimatedText>
					<AnimatedText delay={1.3}>
						a governança e privacidade de dados na sua empresa
					</AnimatedText>
					<AnimatedText delay={1.3}>
						estão em conformidade com a LGPD.
					</AnimatedText>
					<AnimatedText delay={1.6}>
						<div className="mt-6">Vamos lá?</div>
					</AnimatedText>
				</div>

				<Shape name="white" className="shape" />
				<Shape name="line" className="line" />

				<div className="eliza rounded-[350px] overflow-hidden">
					<Eliza name="home" className=" object-contain w-full h-full" />
				</div>

				<LogoOCB white className="logo-ocb" />
			</div>
		</>
	);
}

import { useDialog } from "context/DialogContext";
import { useEffect, useRef, useState } from "react";
import Typewriter from "typewriter-effect";

const avatar = require("assets/images/avatar.png");

export default function Dialog() {
	const { dialogData, closeDialog } = useDialog();

	const [dialogIndex, setDialogIndex] = useState(-1);
	const [dialogFinished, setDialogFinished] = useState(false);

	const typewriterRef = useRef<any>(null);

	function next() {
		if (!dialogFinished) return;

		const total = dialogData.length;
		const nextIndex = dialogIndex + 1;
		if (nextIndex < total) {
			setDialogIndex(nextIndex);
		} else {
			closeDialog();
		}
	}

	function canContinue() {
		return dialogIndex < dialogData.length - 1;
	}

	function startDialog() {
		if (!typewriterRef.current || dialogIndex < 0) return;

		const wrapper = document.querySelector(".Typewriter__wrapper");
		if (wrapper) {
			wrapper.innerHTML = "";
		}

		setDialogFinished(false);

		typewriterRef.current
			.typeString(dialogData[dialogIndex].content)
			.callFunction(() => {
				setDialogFinished(true);
			})
			.start();
	}

	useEffect(() => {
		// console.log(dialogData);
		setDialogIndex(-1);
		setDialogFinished(false);
	}, [dialogData]);

	useEffect(() => {
		startDialog();
	}, [typewriterRef, dialogIndex]);

	if (!dialogData) return null;

	return (
		<div
			onClick={() => next()}
			className={`${
				dialogFinished ? "cursor-pointer" : "cursor-default"
			} ring-4 ring-white fixed flex flex-row items-center left-10 right-10 bottom-5 shadow-xl min-h-[200px] bg-black z-30 bg-opacity-95 text-white text-4xl py-5 px-10`}
		>
			{dialogFinished && (
				<span className="text-white absolute top-3 right-3 text-2xl text-neon-blue animate-pulse">
					{canContinue() ? "CONTINUAR" : "FECHAR"}
				</span>
			)}
			<div className=" -left-16 relative text-center">
				<img src={avatar} className="rounded-full w-40 h-40 ring-4" />
				<b className="text-2xl text-neon-pink">Polly</b>
			</div>
			<div id="dialog-text" className="flex-1 relative">
				<Typewriter
					options={{
						delay: 30,
					}}
					onInit={(typewriter) => {
						typewriterRef.current = typewriter;
						setDialogIndex(0);
						startDialog();
					}}
				/>
			</div>
		</div>
	);
}
